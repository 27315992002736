import "core-js/modules/es.array.push.js";
import SplitPane from '@/components/SplitPane/SplitPane.vue';
import { defineComponent, provide, reactive, toRefs, onBeforeUnmount, watch, defineAsyncComponent, nextTick } from 'vue';
import LiveMap from './LiveMap.vue';
import LiveSide from './LiveSide.vue';
import APIPos from '@/api/live';
import { useMessage } from '@/hooks/web/useMessage';
import { isNullOrUnDef } from '@/utils/is';
import { useDebounce } from '@/hooks/core/useDebounce';
import { hasAuth } from '@/utils/auth';
export default defineComponent({
  name: 'Live',
  components: {
    LiveMap,
    LiveSide,
    SplitPane,
    LiveFence: defineAsyncComponent(() => import('./LiveFence.vue')),
    CmdDialog: defineAsyncComponent(() => import('@/components/Instruction')),
    MpttDialog: defineAsyncComponent(() => import('@/components/MpttFormDialog.vue')),
    StudentInfo: defineAsyncComponent(() => import('@/components/StudentInfo/StudentInfo.vue'))
  },
  // setup(props, { attrs, emit, expose, slots })
  setup() {
    const messageBox = useMessage();
    const intervalObj = {
      ms: 1,
      timerId: null
    };
    const refData = reactive({
      refMap: null,
      refFence: null,
      fenceObj: {
        visible: false,
        mapZoom: 12,
        mapCenter: null
      },
      left: {
        min: 20,
        max: 30,
        default: 20
      },
      right: {
        min: 70,
        max: 80,
        default: 80
      }
    });
    const refRoot = reactive({
      // 员工列表
      studentList: [],
      // 加载员工列表时的加载状态
      studentLoading: false,
      // 选择的岗位类型ID
      filterClassIds: [],
      // 加载员工在线状态
      filterType: 0,
      // 员工列表总数
      studentTotal: 0,
      // 员工列表在线数
      studentOnline: 0,
      // 员工列表离线数, 包括从未上线
      studentOffline: 0,
      // 员工列表勾选员工的cardId列表
      studentCheckedIds: [],
      // 当前选择的员工证Id
      currentCardId: null,
      // 当前岗位类型Id
      currentClassId: null,
      updateTime: 0,
      // 指令下发Dialog参数
      cmdProps: {
        visible: false,
        objectid: 0
      },
      // 员工证详细
      cardDetailProps: {
        objectid: 0,
        visible: false
      },
      //mptt详细
      mpttDetailProps: {
        objectid: 0,
        visible: false
      },
      //当前页数
      pageNo: 1,
      //分页个数  
      pageSize: 1200
    });
    async function getStudentPos() {
      try {
        const holdIds = refRoot.filterClassIds;
        const type = refRoot.filterType;
        const pageSize = refRoot.pageSize;
        const pageNo = refRoot.pageNo;
        const mdtid = refRoot.filterImei || '';
        if (hasAuth(1201) || mdtid != '')
          //拥有管理权限，查询所有设备
          {
            const result = await APIPos.getStudentPos({
              holdIds,
              type,
              mdtid,
              pageNo,
              pageSize
            });
            if (result.code !== 0) throw result;
            const {
              posList,
              total,
              online,
              offline
            } = result.data;
            refRoot.studentTotal = total;
            refRoot.studentOnline = online;
            refRoot.studentOffline = offline;
            refRoot.studentList = posList;
            // mergeStudentList(posList);
          }
        // 更新数据
      } catch (error) {
        messageBox.warning(error.msg);
        //console.error(error);
      }
    }
    async function loadStudent() {
      try {
        refRoot.studentLoading = true;
        refRoot.studentList = [];
        const classIds = refRoot.filterClassIds;
        const mdtid = refRoot.filterImei || '';
        if (classIds.length === 0) return;
        if (hasAuth(1201) || mdtid != '')
          //拥有管理权限，查询所有设备
          {
            await getStudentPos();
            startTiming();
          }
      } catch (error) {
        //console.error(error);
      } finally {
        refRoot.studentLoading = false;
      }
    }
    function startTiming() {
      const updateTime = 30;
      refRoot.updateTime = updateTime;
      intervalObj.timerId = setInterval(() => {
        if (!intervalObj.timerId) return;
        refRoot.updateTime--;
        if (refRoot.updateTime <= 0) {
          getStudentPos();
          refRoot.updateTime = updateTime;
        }
      }, 1000);
    }
    function stopTiming() {
      clearInterval(intervalObj.timerId);
      intervalObj.timerId = null;
      refRoot.studentTotal = 0;
      refRoot.studentOnline = 0;
      refRoot.studentOffline = 0;
      refRoot.updateTime = 0;
      refRoot.currentCardId = null;
      refRoot.studentCheckedIds = [];
      refRoot.studentList = [];
      refRoot.studentLoading = false;
    }
    function initProvide() {
      provide('rootProvide', refRoot);
    }
    // 合并
    function mergeStudentList(posList) {
      if (refRoot.studentList.length === 0) {
        refRoot.studentList = posList;
        return;
      }
      // 移除posList不存在的员工, 因为是全量更新,  所以更新的数据是所有最新的数据
      for (let i = refRoot.studentList.length - 1; i >= 0; i--) {
        const student = refRoot.studentList[i];
        if (posList.some(pos => pos.objectid === student.objectid)) continue;
        refRoot.studentList.splice(i, 1);
      }
      setTimeout(() => {
        while (posList.length) {
          const pos = posList.shift();
          // 下标索引
          const index = refRoot.studentList.findIndex(student => student.objectid === pos.objectid);
          // 新增
          if (index === -1) {
            refRoot.studentList.push(pos);
          } else {
            // 合并项
            Object.assign(refRoot.studentList[index], pos);
          }
        }
      }, 400);
    }
    // 添加围栏
    async function addFence(drawType) {
      if (isNullOrUnDef(refData.refMap)) return;
      if (isNullOrUnDef(refData.refFence)) return;
      const mapOptions = refData.refMap.getMapOptions();
      refData.fenceObj.mapZoom = mapOptions.zoom;
      refData.fenceObj.mapCenter = mapOptions.center;
      // 清空地图上的围栏
      refData.refMap.renderPolygon();
    }
    // 点击围栏
    function fenceClick(row) {
      if (isNullOrUnDef(row)) {
        // 清空地图上的围栏
        refData.refMap.renderPolygon();
        return;
      }
      if (row.type === 1) {
        refData.refMap.renderRound({
          lon: row.centerLon,
          lat: row.centerLat
        }, row.radius);
      } else if (row.type === 2) {
        refData.refMap.renderPolygon(row.pointList);
      }
    }
    const [debounceLoadStudent] = useDebounce(loadStudent, 300);
    watch([() => refRoot.filterClassIds, () => refRoot.filterType, () => refRoot.pageNo, () => refRoot.filterImei], async () => {
      stopTiming();
      refRoot.studentLoading = true;
      await nextTick();
      debounceLoadStudent();
      // loadStudent();
    }, {
      deep: true
    });
    onBeforeUnmount(() => {
      stopTiming();
    });
    initProvide();
    return {
      ...toRefs(refData),
      ...toRefs(refRoot),
      addFence,
      fenceClick
    };
  }
});